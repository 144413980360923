import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__redo" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.undo && _ctx.undo(...args))),
      disabled: !_ctx.canUndo
    }, [
      _createVNode(_component_BaseIcon, { icon: "header/redo/backward" })
    ], 8, _hoisted_2),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.redo && _ctx.redo(...args))),
      disabled: !_ctx.canRedo
    }, [
      _createVNode(_component_BaseIcon, { icon: "header/redo/forward" })
    ], 8, _hoisted_3)
  ]))
}