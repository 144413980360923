import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font__style" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, {
    modifier: _ctx.name,
    name: "font",
    title: "Font"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("select", {
          class: "canvas__select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
          id: ""
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.googleFonts, (font, key) => {
            return (_openBlock(), _createElementBlock("option", {
              key: key,
              value: font.family
            }, _toDisplayString(font.family), 9, _hoisted_2))
          }), 128))
        ], 512), [
          [_vModelSelect, _ctx.localValue]
        ])
      ])
    ]),
    _: 1
  }, 8, ["modifier"]))
}