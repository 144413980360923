import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content__media__add__image"
}
const _hoisted_2 = {
  key: 1,
  class: "content__style__media__upload__has__img"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "content__media__upload__container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleUploadClick && _ctx.handleUploadClick(...args))),
    onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
    onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args))),
    class: _normalizeClass(["content__style__media__upload", { disabled: _ctx.isLoading }])
  }, [
    (!_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Add Image"))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.isHovered)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.modelValue,
                alt: ""
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createVNode(_component_BaseIcon, { icon: "canvas/panel/styles/media/upload" })
              ])),
          _cache[4] || (_cache[4] = _createElementVNode("span", null, "filename.jpg", -1))
        ])),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_BaseIcon, {
          key: 2,
          class: "loader",
          icon: "loader"
        }))
      : (!_ctx.modelValue)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 3,
            icon: "canvas/panel/styles/media/upload"
          }))
        : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "file",
      class: "hidden",
      ref: "fileInputRef",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageUpload && _ctx.handleImageUpload(...args))),
      accept: ".png, .jpg, .jpeg, .gif"
    }, null, 544)
  ], 34))
}