import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "canvas__sidebar__nav" }
const _hoisted_2 = {
  key: 1,
  class: "sidebar__nav__top"
}
const _hoisted_3 = { class: "sidebar__nav__bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CanvasSidebarNavTopSkeleton = _resolveComponent("CanvasSidebarNavTopSkeleton")!
  const _component_BaseButtonIcon = _resolveComponent("BaseButtonIcon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_ctx.canvasLoading)
      ? (_openBlock(), _createBlock(_component_CanvasSidebarNavTopSkeleton, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarNavTopIcons, (button, key) => {
            return (_openBlock(), _createBlock(_component_BaseButtonIcon, {
              key: key,
              onClick: ($event: any) => (_ctx.changeSidebarNavContent(button.name)),
              icon: button.icon,
              class: _normalizeClass({ active: _ctx.activeContent(button.name) })
            }, null, 8, ["onClick", "icon", "class"]))
          }), 128))
        ])),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarNavBottomIcons, (button, key) => {
        return (_openBlock(), _createBlock(_component_BaseButtonIcon, {
          key: key,
          icon: button.icon
        }, null, 8, ["icon"]))
      }), 128)),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleUserInitials && _ctx.toggleUserInitials(...args))),
        id: "modals-trigger",
        class: "sidebar__nav__bottom__initials"
      }, _toDisplayString(_ctx.getInitials), 1)
    ])
  ]))
}