import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseBorderStyle = _resolveComponent("BaseBorderStyle")!
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, {
    modifier: _ctx.name,
    title: "Border top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseBorderStyle, {
        "model-value": _ctx.border,
        "onUpdate:modelValue": _ctx.updateBorder
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ]),
    _: 1
  }, 8, ["modifier"]))
}