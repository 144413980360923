import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "number__row__style__container" }
const _hoisted_2 = { class: "number__row__style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, {
    modifier: _ctx.name,
    name: _ctx.name
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "panel__style__head" }, [
          _createElementVNode("h5", null, "Margin bottom")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localStyleValue) = $event))
          }, null, 512), [
            [_vModelText, _ctx.localStyleValue]
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("span", null, "PX", -1))
        ])
      ])
    ]),
    _: 1
  }, 8, ["modifier", "name"]))
}