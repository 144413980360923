import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content__style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImageTextUpload = _resolveComponent("BaseImageTextUpload")!
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, {
    modifier: _ctx.name,
    title: "IMAGE LINK"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BaseImageTextUpload, {
          modelValue: _ctx.modifier,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modifier) = $event)),
          onConfirm: _ctx.updateImage
        }, null, 8, ["modelValue", "onConfirm"])
      ])
    ]),
    _: 1
  }, 8, ["modifier"]))
}