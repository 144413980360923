<template>
  <div class="sidebar__nav__top__skeleton">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CanvasSidebarNavTopSkeleton",
});
</script>
