import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "canvas__workspace__skeleton__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "canvas__workspace__skeleton" }, [
      _createElementVNode("p", null, "Loading project, please wait..."),
      _createElementVNode("div", { class: "canvas__workspace__skeleton__items" }, [
        _createElementVNode("div"),
        _createElementVNode("div")
      ])
    ], -1)
  ])))
}