import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "shadow__style" }
const _hoisted_2 = { class: "shadow__style__tab__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSliderIcon = _resolveComponent("BaseSliderIcon")!
  const _component_ColorPickerStyle = _resolveComponent("ColorPickerStyle")!
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, {
    modifier: _ctx.name,
    title: "Shadow"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseSliderIcon, {
            modelValue: _ctx.shadow.y,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shadow.y) = $event)),
            icon: "canvas/panel/styles/shadow/y"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_BaseSliderIcon, {
            modelValue: _ctx.shadow.x,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shadow.x) = $event)),
            icon: "canvas/panel/styles/shadow/x"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_BaseSliderIcon, {
            modelValue: _ctx.shadow.blur,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shadow.blur) = $event)),
            icon: "canvas/panel/styles/shadow/blur"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_BaseSliderIcon, {
            modelValue: _ctx.shadow.spread,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.shadow.spread) = $event)),
            icon: "canvas/panel/styles/shadow/spread"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_ColorPickerStyle, {
            type: _ctx.ColorPickerTypes.PANEL_BOX_SHADOW_COLOR,
            modelValue: _ctx.shadow.color,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.shadow.color) = $event)),
            title: "Color"
          }, null, 8, ["type", "modelValue"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modifier"]))
}