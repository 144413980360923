import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColorPickerStyle = _resolveComponent("ColorPickerStyle")!
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, {
    modifier: _ctx.name,
    title: "BACKGROUND COLOR"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ColorPickerStyle, {
        type: _ctx.ColorPickerTypes.PANEL_STYLE_BG_COLOR,
        modelValue: _ctx.color,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.color) = $event))
      }, null, 8, ["type", "modelValue"])
    ]),
    _: 1
  }, 8, ["modifier"]))
}