import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "shadow__style" }
const _hoisted_2 = { class: "shadow__style__tab__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSliderIcon = _resolveComponent("BaseSliderIcon")!
  const _component_ColorPickerStyle = _resolveComponent("ColorPickerStyle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseSliderIcon, {
        modelValue: _ctx.localBorder.width,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localBorder.width) = $event)),
        icon: "canvas/panel/styles/border/top"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_ColorPickerStyle, {
        ref: "colorPickerStyleRef",
        type: _ctx.ColorPickerTypes.PANEL_BOX_SHADOW_COLOR,
        modelValue: _ctx.localBorder.color,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localBorder.color) = $event)),
        title: "Color"
      }, null, 8, ["type", "modelValue"])
    ])
  ]))
}