import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "preview__text__style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, {
    modifier: `general-${_ctx.name}`,
    title: "Preview text"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("textarea", {
          class: "canvas__textarea",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
          placeholder: "Add email preview text..."
        }, "      ", 512), [
          [_vModelText, _ctx.localValue]
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("p", null, "This text will be shown by some email clients as preview text", -1))
      ])
    ]),
    _: 1
  }, 8, ["modifier"]))
}