<template>
  <div class="header__container__right__skeleton">
    <div></div>
    <div></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "HeaderContainerRightSkeleton",
});
</script>

<style></style>
