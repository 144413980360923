import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "header__home__skeleton"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.canvasLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: "/projects",
        class: "header__home"
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", null, [
            _createVNode(_component_BaseIcon, { icon: "arrow/left" })
          ]),
          _createElementVNode("button", null, [
            _createVNode(_component_BaseIcon, { icon: "header/home" })
          ])
        ]),
        _: 1
      }))
}